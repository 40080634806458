<template>
    <basic-page class="progetto" addedclass="white-bg"
        :pagedata="this.pageData" 
        :bannerimg="backgroundImage">

        <div v-if="this.pageData.attributes.field_body_01" class="container-espe" 
            v-html="this.pageData.attributes.field_body_01.value">
        </div>

        <div class="container-espe variant-bg backstep scheda">
            <h2 class="central">
                Come funziona ExpeRotary
            </h2>
            
            <div :class="['video-container', 'video_hq', { videoPlaying: isPlaying }]" 
                ref="videoContainer" @click="playVideo('video_hq')">
                <div class="controls" ref="videoControls">
                    <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                    <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                </div>

                <video ref="video_hq" @ended="onEnd()" poster="../assets/poster.jpg">
                    <source src="../assets/video/video.mp4" type="video/mp4">
                </video>
            </div>

            <div :class="['video-container', 'video_low', { videoPlaying: isPlaying }]" 
                ref="videoContainer" @click="playVideo('video_low')">
                <div class="controls" ref="videoControls">
                    <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                    <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                </div>

                <video ref="video_low" @ended="onEnd()" poster="../assets/poster.jpg">
                    <source src="../assets/video/video-mobile.mp4"  type="video/mp4">
                </video>
            </div>

            <div class="phases">
                <div class="phase">
                    <img class="phase-logo" src="../assets/icon-come-funziona-trova.svg" alt="">
                    <h1 class="num">1</h1>
                    <div class="content">
                        <h4 class="name">TROVA</h4>
                        <p class="text">Con chi ti piacerebbe poter avere un confronto?</p>
                    </div>
                </div>
                <div class="phase">
                    <img class="phase-logo" src="../assets/icon-come-funziona-presentati.svg" alt="">
                    <h1 class="num">2</h1>
                    <div class="content">
                        <h4 class="name">PRESENTATI</h4>
                        <p class="text">Così l’esperto che hai selezionato saprà chi incontrerà</p>
                    </div>
                </div>
                <div class="phase">
                    <img class="phase-logo" src="../assets/icon-come-funziona-conferma.svg" alt="">
                    <h1 class="num">3</h1>
                    <div class="content">
                        <h4 class="name">CONFERMA</h4>
                        <p class="text">Hai la possibilità di fare libera donazione alla causa che preferisci per confermare la richiesta: se scegli di donare, noi doneremo altrettanto per raddoppiare l’impatto</p>
                    </div>
                </div>
                <div class="phase">
                    <img class="phase-logo" src="../assets/icon-come-funziona-confronto.svg" alt="">
                    <h1 class="num">4</h1>
                    <div class="content">
                        <h4 class="name">CONFRONTO</h4>
                        <p class="text">60 minuti di confronto con la persona che può aiutarti a costruire il futuro</p>
                    </div>
                </div>
                <div class="phase">
                    <img class="phase-logo" src="../assets/icon-come-funziona-raccontaci.svg" alt="">
                    <h1 class="num">5</h1>
                    <div class="content">
                        <h4 class="name">RACCONTACI</h4>
                        <p class="text">Com’è andata così da poter migliorare anche grazie ai tuoi consigli</p>
                    </div>
                </div>
            </div>

        </div>
        <div class="container-espe variant-bg button-container">
            <button class="btn">
                <router-link to="/gli-esperti">
                    TROVA LA PERSONA GIUSTA
                </router-link>
            </button>
        </div>
    </basic-page>
</template>

<script>
import BasicPage from './BasicPage.vue'
import { fetchSinglePage, fetchSingleNode } from '../libs/drupalClient'
export default {
    components: { BasicPage },
    name: 'il-progetto',
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: "56537a3e-50ac-43df-9040-c1613fc1832d",
            isPlaying: false,
            bannerData: {}
        }
    },
    methods: {  
        play(video) {
            this.$refs[video].play()
        },
        pause(video) {
            this.$refs[video].pause()
        },
        onEnd() {
            this.isPlaying = false
        },
        playVideo(video) {
            if (!this.isPlaying) {
                this.play(video)
                this.isPlaying = true
            } else {
                this.isPlaying = false
                this.pause(video)
            }
        },
        onresize() {
            console.log('prova')
            if(this.isPlaying) {
                this.$refs['video_hq'].pause()
                this.$refs['video_low'].pause()
                this.isPlaying = false
            }
        }
    },
    computed: {
        backgroundImage() {
            if(this.bannerData.field_banner_top)
                return this.bannerData
            else 
                return {}
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    mounted() {
        window.addEventListener("resize", this.onresize)

        fetchSinglePage('page_type_01', this.currentPageId, { include: ['field_ref_banner_top'] }, this.lang)
        .then(res => {
            this.pageData = res[0]

            fetchSingleNode('banner', this.pageData.field_ref_banner_top.id, { include: ['field_banner_top'] }, this.lang)
            .then(res => {
                this.bannerData = res[0]
            })
        })
    }
}
</script>

<style lang="scss" scoped>
.btn a {
    text-decoration: none;
    color: #fff;
}
</style>